<template>
  <div class="inline-block">
    <div
      @click="login"
      style="width: 200px; height: 30px; z-index: 99999"
      class="cursor-pointer absolute"
    ></div>
    <div href="" class="fb connect">
      <vs-avatar
        :src="require(`@/assets/images/portrait/icons/facebook_white.png`)"
        style="background: none !important"
        size="20px"
      ></vs-avatar>
      <p style="margin-right: 5px">{{ $t("PageLoginWithFacebook") }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FacebookLoginButton",
  props: {
    integration: {
      type: String,
      required: true,
      default: "facebook",
    },
  },
  data() {
    return {
      status: {
        facebookAuth: {},
      },
      facebookPermissions: [
        { permission: "pages_show_list", status: "granted" },
        { permission: "pages_messaging", status: "granted" },
        { permission: "pages_manage_metadata", status: "granted" },
        { permission: "public_profile", status: "granted" },
      ],
      instagramPermissions: [
        { permission: "pages_show_list", status: "granted" },
        { permission: "pages_messaging", status: "granted" },
        { permission: "pages_manage_metadata", status: "granted" },
        { permission: "instagram_basic", status: "granted" },
        { permission: "instagram_manage_messages", status: "granted" },
      ],
      loginWithInstagramCalled: false,
      loginWithFacebookCalled: false,
    };
  },
  mounted() {
    this.loadFacebookSDK(document, "script", "facebook-jssdk");
    this.initFacebook();
    if (this.$store.state.auth.redirect) {
      localStorage.setItem("redirect", this.$store.state.auth.redirect);
    }
    //window.SocialLogin = this.SocialLogin;
  },
  methods: {
    async login() {
      if (this.integration === "facebook") this.loginWithFacebookCalled = true;
      else this.loginWithInstagramCalled = true;
      window.FB.login(
        async (response) => {
          if (response && response.authResponse) {
            await this.checkPermissionsAndLogin(response);
          }
        },
        {
          scope:
            this.integration === "facebook"
              ? "public_profile,pages_show_list,pages_manage_metadata,pages_messaging,business_management"
              : "instagram_basic,pages_manage_metadata,instagram_manage_messages,pages_messaging,pages_show_list,pages_read_engagement,business_management",
        }
      );
    },
    logout() {
      window.FB.api("/me/permissions", "delete", null, () => {
        window.FB.logout();
        this.$emit("logout-facebook");
        this.localLogout();
      });
    },
    localLogout() {
      document.cookie.split(";").forEach(function (c) {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
    },
    async checkPermissionsAndLogin(response) {
      const thisIns = this;

      if (response && response.status === "connected") {
        window.FB.api(
          "/me/permissions",
          "get",
          ({ data: permissionsResponse }) => {
            let haveFacebookPermissions;
            let haveInstagramPermissions;
            if (permissionsResponse) {
              const havePermissions = (permission) => {
                return permissionsResponse.find((responsePermission) => {
                  return (
                    responsePermission.permission === permission.permission &&
                    responsePermission.status === permission.status
                  );
                });
              };
              haveFacebookPermissions =
                thisIns.facebookPermissions.every(havePermissions);
              haveInstagramPermissions =
                thisIns.instagramPermissions.every(havePermissions);
            }
            if (
              (thisIns.integration === "facebook" &&
                !haveFacebookPermissions) ||
              (thisIns.integration === "instagram" && !haveInstagramPermissions)
            ) {
              this.$vs.notify({
                title: this.$t("UnexpectedError"),
                text: this.$t("PageLoginWithFacebookPermissionsFail"),
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger",
                position: "top-right",
                time: 4000,
              });
              return false;
            }

            window.FB.api("/me/accounts", "GET", function (res) {
              thisIns.$emit("pages", res.data);
            });

            thisIns.$emit("login", response.authResponse, thisIns.integration);
            thisIns.localLogout();
          }
        );
      } else {
        this.$vs.notify({
          title: this.$t("UnexpectedError"),
          text: this.$t("PageLoginWithFacebookPermissionsFail"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
          time: 4000,
        });
        return false;
      }
    },
    initFacebook() {
      let facebookAppId = "1642703955853689";
      if (
        process.env.NODE_ENV === "development" ||
        window.location.origin.includes("app2") ||
        window.location.origin.includes("localhost")
      )
        facebookAppId = "1462264037528605";

      window.fbAsyncInit = function () {
        window.FB.init({
          appId: facebookAppId,
          cookie: true,
          xfbml: true,
          version: "v19.0",
        });
      };
    },
    loadFacebookSDK(d, s, id) {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      const js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/pt_BR/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    },
  },
};
</script>

<style>
.fb {
  font-family: Helvetica, Arial, sans-serif !important;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  font-size: 13px;
  padding: 2px 4.5px;
  background: #1877f2 !important;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 20, 0.4);
  text-decoration: none;
  position: relative;
  border-radius: 5px;
}
</style>
